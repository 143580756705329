body {
  margin: 0;
  font-size: var(--font-16px);
  font-weight: var(--font-Normal);
  line-height: 1.5;
  color: var(--body-color);
  text-align: left;
  font-family: var(--ff-rhas);
}

.required-field {
  color: var(--error-text-color);
  font-size: var(--font-12px);
  font-weight: var(--font-Medium);
  pointer-events: auto;
}
.react-date-picker__wrapper {
  align-items: center;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.evt-disabled {
  pointer-events: none;
  cursor: default;
}

.label,
.form-group label {
  color: var(--label-text);
  font-family: var(--ff-rhas);
  font-size: var(--font-13px);
  font-weight: var(--font-Semi-Bold);
  letter-spacing: .1px;
  margin-bottom: 5px;
}

.instruction {
  color: var(--instruction-text-color);
  font-family: var(--ff-rhas);
  font-size: var(--font-14px);
  font-weight: var(--font-Normal);
  line-height: 1.5;
}

.page-title {
  color: var(--section-header-text-color);
  font-size: var(--font-18px);
  font-weight: var(--font-Medium);
  font-family: var(--ff-montserrat);
}

.general-text {
  color: var(--general-text-color);
  font-family: var(--ff-rhas);
  font-size: var(--font-14px);
  font-weight: var(--font-Medium);
  letter-spacing: .1px;
}

.tex-overflow {
  text-overflow: ellipsis;
  text-wrap: nowrap;

}

.text-shadow {
  text-shadow: 2px 2px 5px var(--section-header-text-color);
}

h1 {
  font-size: var(--font-26px);
  font-family: var(--ff-montserrat);
}

h2 {
  font-size: var(--font-22px);
  font-family: var(--ff-montserrat);
}

h3 {
  font-size: var(--font-18px);
  font-family: var(--ff-montserrat);
}

h4 {
  font-size: var(--font-16px);
  font-family: var(--ff-montserrat);
}

h5 {
  font-size: var(--font-13px);
  font-family: var(--ff-montserrat);
}

h6 {
  font-size: var(--font-12px);
  font-family: var(--ff-montserrat);
}

ul {
  padding-left: 0px;

  li {
    list-style: none;
  }
}

.form-group {
  margin-bottom: 15px;
}

.form-control-utils {
  color: var(--general-text-color);
  font-family: var(--ff-rhas);
  font-size: var(--font-13px);
  font-weight: var(--font-wt500);
  min-width: 150px;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: var(--primary-btn-Assign-color);
  border: 1px solid var(--searchbar-border-color);
}

.form-control-utils:focus,
.input-field:focus-visible,
.input-field:focus-within {
  outline: none;
  border: 1px solid var(--searchbar-border-highlight-color);
}

hr {
  width: 100%;
  margin-top: 0rem;
  margin-bottom: 20px;
}

a,
a:hover {
  text-decoration: none;
}

.form-check-input:checked {
  background-color: var(--text-active-color);
  border-color: var(--text-active-color);
  opacity: 1;
}

.form-check-input {
  background-color: var(--custom-control-btn-disabled);
  border-color: var(--custom-control-btn-disabled);
  opacity: 1 !important;
}

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}

.modal-header .btn-close:focus {
  box-shadow: none;
}

.input-field-widget {
  color: var(--input-text-color);
  font-family: var(--ff-rhas);
  font-size: var(--font-16px);
  font-weight: var(--font-Medium);
  min-width: 60px;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: var(--primary-btn-Assign-color);
  border: 1px solid var(--searchbar-border-color);
}

.input-field-widget:focus,
.input-field-widget:focus-visible,
.input-field-widget:focus-within {
  outline: none;
  border: 1px solid var(--searchbar-border-highlight-color);
}

.input-field-widget:disabled,
.input-field-widget[readonly] {
  cursor: not-allowed;
  background-color: var(--input-placeholder-disabled);
}

.form-group {
  margin-bottom: 15px;
}

.display-hide {
  display: none;
}
.css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 3px !important;
}

.form-control-dashboard {
  width: 200px;
}

.input-field-profile {
  width: 100%;
}

/* Heading line */
.head-line {
  display: block;
  margin-bottom: 10px;
}

.renderOption {
  font-size: var(--font-14px);
}

.taskTemplateOptionColor{
  color: var(--primary-btn-color);
}

/* autocomplete-input-text */
.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input,
.MuiAutocomplete-inputRoot[class*="MuiInput-root"] {
  color: var(--autocomplete-input-text-color);
  cursor: pointer;
}

/* loader icon margin top  */
.mt-5,
.my-5 {
  margin-top: 0rem !important;
}

.big-loader {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  background-color:var( --active-bg-color);
  top: 0;
  /* //   .spinner-grow {
  //     position: relative;
  //     top: 50vh;
  //   } */
}

.grid-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

ul li {
  list-style: none;
}

input[type=date],
input[type=date]:focus,
input[type=file],
input[type=file]:focus,
input[type=text],
input[type=text]:focus,
select,
select option {
  color: var(--input-text-color);
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn {
  font-size: var(--font-14px);
}

.no-scroll {
  overflow: hidden;
}

/* common.scss styles */

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color:var(--webkit-tap-highlight-color);
}

input[type="date"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

input[type="text"]::-webkit-input-placeholder,
input[type="textarea"]::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  /* Edge */
  color:var(--form-control-webkit-color);
}

input[type="text"]:-ms-input-placeholder,
input[type="textarea"]:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  /* Internet Explorer */
  color: var(--form-control-webkit-color);
}

input[type="text"]::placeholder,
input[type="textarea"]::placeholder,
.form-control::placeholder {
  color:var( --form-control-webkit-color);
}

.list-group.stop-propagation {
  border-bottom: 0px;
}


/* Date Picker */
.react-date-picker__wrapper {
  border: none !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.center {
  text-align: center;
}

/* Class */

/* Container Card Box */
.card-box {
  background: var(---primary-white);
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 1px 3px 0px var( --close-call-section-box-shawdow-color1),
    0px 1px 1px 0px var( --product-key-plus-box-shadow-color-2),
    0px 2px 1px -1px var(--product-key-plus-box-shadow-color-3);
}

.p-bottom-15 {
  padding-bottom: 15px;
}

@media (max-width: 767px) {
  .card-box .section-header h3 {
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .mob-pad {
    padding: 10px;

  }
}

.btn-primary {
  color: var(---primary-white);
  border-color:var( --sf-chnnelDropdown-border-color);
  background-color: var(  --sso-hover-color);
  transition: ease-in-out 0.5s;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:not(:disabled):not(.disabled):active {
  color: var(---primary-white);
  border-color: var(  --primary-hover-color);
  background-color:var(--primary-hover-color);
  transition: ease-in-out 0.5s;
}

@media (max-width: 991px) {
  #main {
    margin-left: 0px !important;
  }
}

/* .MuiAutocomplete-option[aria-selected="true"] {
  background-color: var( --primary-white) !important
}

.MuiAutocomplete-option[data-focus="true"] {
  background-color: var( --nav-active-background-color) !important
}

.MuiAutocomplete-option:active {
  background-color:var(--MuiAutocomplete-option-active-color) !important
}


.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input::placeholder {
  color:var( --profile-text-color);
  opacity: 1;
  cursor: pointer;
} */

.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input,
.MuiAutocomplete-inputRoot[class*="MuiInput-root"] {
  cursor: pointer;
}

.MuiInputBase-input.Mui-disabled {
  /* opacity: 0.3 !important; */
  pointer-events: none;
  padding: 10px;
}

.MuiTablePagination-menuItem {
  display: flex !important;
  font-size: 12px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  box-sizing: border-box !important;
  white-space: nowrap !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-weight: 400 !important;
}

/* need to remove below mentioned code once all pages styling consistancy covered  */
.form-control {
  border-radius: 0.25rem;
  color:var( --rpt-popup-filter-searchbar-text-color);
  font-family:var(    --ff-rs);
  font-size: var(--font-13px);
  font-weight:var(--font-Medium);
  height: 34px;
  min-width: 60px;
  padding-left: 10px;
  padding-right: 10px;
}
.fa-pause-circle-color {
  color: var(--fa-pause-color);
}

.fa-check-circle-color {
  color: var(--fa-check-circle-color) !important;
}
.fa-times-circle-color {
  color: var(--fa-times-color) !important;
}

.btnspacing {
  margin-left: 10px;
}
.section-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.colsm12{
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
}

.buttonlightblue{
  color: var(--bs-link-color);
  cursor: pointer;
}

.MuiAlert-message{
font-weight: 400;
font-size: 14px;
}

.MuiDialogContent-root{
  padding-top: 8px !important;
}
 
/* HighChart React clicked categories default style override  */

.highcharts-legend-item-hidden text {
  text-decoration: none !important; 
  fill: rgb(204, 204, 204) !important;
  color: rgb(204, 204, 204) !important;
}

.highcharts-legend-item-hidden rect {
  text-decoration: none !important;
  fill: rgb(204, 204, 204) !important;
  color: rgb(204, 204, 204) !important;
}

.MuiAutocomplete-option[aria-selected="true"] {
  background-color:var(--MuiAutocomplete-option-active-color) !important;
}

.classFlex{
   display: flex;
   align-items: center;
   justify-content: center;
}

.viewMessageBtn{
  font-family: var(--ff-rhas);
  font-weight: var(--font-Medium);
  color: var(--view-message-btn-color);
  cursor: pointer;
}
 /* To Disable DataGrid coloumn scroll border  */
.MuiDataGrid-withBorderColor {
  border: none;
}

.exportCsvXlsv {
  font-size: var(--font-14px);
  cursor: pointer;
}

@media (min-width: 1200px) {
  .col-xl-3 {
      max-width: 25%;
  }
}

@media only screen and (max-width: 767px) {

  .dataTables_length {
    margin-bottom: 5px;
  }
  .section-header {
    align-items: initial;
  }
  .section-header h2 {
    width: 100%;
    border-bottom: 1px solid var(--primary-black);
  }
}

.progress {
  height: 5px !important;
  width: 100%;
  margin: 0;
  z-index: auto;
  bottom: 5px;
  background-color: var(--primary-white);
}
.progress-bar {
  background-color: var(--importUser-progressBar-color2);
}