.button-style {
    min-width: 80px;
    border: none;
    box-shadow: none;
    line-height: normal;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    padding: 11.5px 15px;
    font-family: var(--ff-rhas);
    font-size: var(--font-14px);
}

.link-btn {
    color: var(--link-text-color);
    font-family: var(--ff-rhas);
    font-size: var(--font-14px);
    font-weight: var(--font-Medium);
    border: none;
    background: none;
    cursor: pointer;
}

.light .primary-btn {
    background-color: var(--primary-btn-color);
    color: var(--primary-btn-text-color);
}
.green .primary-btn {
    background-color: var(--Accu-button-color);
    color: var(--primary-btn-text-color);
}

.secondary-btn {
    background-color: var(--secondarybtn-color);
    border-color: var(--secondarybtn-color);
    color: var(--secondarybtn-text-color);
}

.danger-btn {
    background-color: var(--danger-btn-delete-color);
    color: var(--danger-btn-text-color);
}

.disabled-btn {
    background-color: var(--button-disabled-color);
    color: var(--button-disabled-text-color);
    cursor: not-allowed !important;
}

.default-btn {
    background-color: var(--button-default-color);
    border: 1px solid var(--button-default-boder-color);
    padding: 9.5px 15px;
}

.btn-align {
    text-align: right;
}

.btn-align-left {
    text-align: left;
}

.primary-btn:disabled {
    cursor: not-allowed;
    background-color: var(--primary-btn-disable-bg-color);
}
/* .green.primary-btn:disabled {
    cursor: not-allowed;
    background-color: var(--Accu-button-disabled-color-uat) !important;
} */
.primary-btn:active,
.primary-btn:hover,
.primary-btn:not(:disabled):not(.disabled):active {
    background-color: var(--primary-hover-color);
    color: var(--primary-btn-text-color);
    transition: .5s ease-in-out;
}
.green .primary-btn:active,
.green .primary-btn:hover,
.green .primary-btn:not(:disabled):not(.disabled):active {
    background-color: var(--Accu-button-hov-color);
    color: var(--primary-btn-text-color);
    transition: .5s ease-in-out;
}

.secondary-btn.focus,
.secondary-btn:focus,
.secondary-btn:hover,
.secondary-btn:not(:disabled):not(.disabled):active  {
    background-color: var(--secondarybtn-hover-color);
    color: var(--secondarybtn-text-color);
}

.secondary-btn:disabled {
    cursor: not-allowed;
    background-color: var(--secondarybtn-disable-color);
}

.sso-btn {
    background-color: var(--sso-btn-bg-color);
    border-radius: 50px;
    box-shadow: 0 0 3px var(--sso-box-shadow-color);
    border: none ;
    font-family: var(--ff-rhas);
    line-height: normal;
    outline: none ;
    padding: 11px 20px;
    color: var(--sso-btn-color);
    transition: .5s ease-in-out;
    width: 100%;
}

.sso-btn:hover {
    background-color: var(--sso-hover-bg-color);
    transition: ease-in-out 0.5s;
    color: var(--sso-hover-color);
}

.login-btn {
    font-family: var(--ff-rhas);
    line-height: initial;
    border: none ;
    box-shadow: none ;
    outline: none ;
    border-radius: 50px;
    min-height: 36px;
    padding: 10px 20px;
}

.modal-header .btn-close{
    color: var(--primary-black);
    float: right;
    font-size: var(--font-12px);
    font-weight: var(--font-Bold);
    line-height: 1;
    opacity: .5;
    text-shadow: 0 1px 0 var(--primary-white);
}

.modal-header .btn-close:hover {
    opacity: 1; 
    text-shadow: 0 1px 0 var(--primary-black); 
}

@media (max-width: 767px) {
  .button-style{
    padding: 9.5px 11px;
  }
}
/* ssoserives  */