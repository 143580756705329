.footer-overwrite {
  border: none !important;
}

.alert-btn-ctn {
  margin-top: 20px;
}

.alert-modal-header {
  display: flex;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.alert-modal-title {
  color: var(--modal-title-text-color);
  font-size: var(--font-18px) !important;
  font-weight: var(--font-Medium);
  font-family: var(--ff-montserrat);
}

.alert-modal-body {
  color: var(--modal-body-text-color);
  font-size: var(--font-14px);
  font-weight: var(--font-Normal);
  font-family: var(--ff-rhas);
}

.modal-footer {
  padding-bottom: 0px;
  padding-right: 0px;
}

.modal-content {
  padding: 10px 15px;
}

.modal-lg {
  max-width: 700px;
}

.mui-title {
  border-bottom: 1px solid var(--mui-border-bottom);
  padding: 18px 0px 8px 0px !important;
  margin: 0px 15px !important;
}

.mui-modal-body {
  color: var(--modal-body-text-color);
  font-size: var(--font-14px);
  font-weight: var(--font-Normal);
  font-family: var(--ff-rhas);
}

.mui-footer {
  margin-bottom: 8px !important;
  margin-right: 12px;
}

.mui-close-icon {
  color: var(--mui-close-icon);
  cursor: pointer;
  font-weight: var(--font-Bold);
  font-size: var(--font-14px);
}

.mui-close-icon:hover {
  color: var(--mui-close-icon-hover);
}