.nav-tabs>li.active>div,
.nav-tabs>li.active>div:focus,
.nav-tabs>li.active>div:hover {
  border-bottom: 2px solid var(--nav-active-parent-underline-color);
  background-color: var(--nav-active-background-color);
  color: var(--primary-black);
  font-size: var(--font-14px);
  font-weight: var(--font-Semi-Bold);
  cursor: unset;
}
/* .greenAccunavtabs{
  border-bottom: 2px solid var(--Accu-button-color);
} 
.navsafetabs{
  border-bottom: 2px solid var(--nav-active-parent-underline-color);
} */
.green .nav-tabs>li.active>div,
.green .nav-tabs>li.active>div:focus,
.green .nav-tabs>li.active>div:hover {
  border-bottom: 2px solid var(--Accu-button-color);
}
 .nav-tabs>li>div {
  background-color: var(--secondarybtn-text-color);
  color: var(--tab-inactive-text-color);
  padding: 10px;
  font-size: var(--font-14px);
  font-family: var(--ff-rhas);
  cursor: pointer;
  font-weight: var(--font-Semi-Bold);
}

.nav-tabs>li:hover>div:hover {
  background-color: var(--nav-active-background-color);
  color: var(--section-header-text-color);
}

.nav-sub-tabs>li.active>div,
.nav-sub-tabs>li.active>div:focus,
.nav-sub-tabs>li.active>div:hover {
  border-bottom: 2px solid var(--nav-active-subitem-underline-color);
  background-color: var(--nav-active-background-color);
  color: var(--section-header-text-color);
  font-size: var(--font-14px);
}

.nav-sub-tabs>li>div {
  background-color: var(--secondarybtn-text-color);
  color: var(--section-header-text-color);
  padding: 10px;
  font-size: var(--font-14px);
  cursor: pointer;
}

.nav-sub-tabs>li:hover>div:hover {
  background-color: var(--nav-active-background-color);
  color: var(--section-header-text-color);
}