.page-header-field {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px
}

.page-header-text {
    font-weight: var(--font-wt500);
    color:var(--section-header-text-color);
    margin-bottom: 0.5rem;
    font-size: var(--font-18px);
    padding: 10px;
    font-family:var(--ff-montserrat);
}

.headline {
    display: block;
}

.pagehead-line {
    margin-top: 0;
    width: 100%;
    border: 0;
    border-top: 1px solid var(--secondarybtn-color);
    box-sizing: initial;
    height: 0;
    overflow: visible;
}
.pageSubTitle {
    color: var(--section-header-text-color);
    font-size: var(--font-16px);
    font-weight: var(--font-Medium);
    font-family: var(--ff-montserrat);
  }
  .settingSubTitle {
    color: var(--section-header-text-color);
    font-size: var(--font-16px);
    font-weight: var(--font-Semi-Bold);
    font-family: var(--ff-rhas);
  }