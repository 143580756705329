.light {
     /* button */
     /* --primary-btn-color: #007bff;
    --primary-btn-text-color: #fff;
     --primary-btn-hover-color: #006592;
     --primary-btn-hover-text-color: #fff;
     --primary-btn-active-color: #8cc1fa;
     --primary-btn-active-text-color: #ffffffF;
     --primary-btn-disable-color: #5a6268;
     --primary-btn-disable-text-color: #fff;
     --primary-btn-disable-border-color: #545b62;
     --danger-btn-delete-color: #e95454;
     --primary-btn-Assign-color: #3d5d7a;
     --primary-btn-Assign-color: #fff;
     --secondarybtn-color: #6c757d;
     --secondarybtn-text-color: #fff;
     --danger-btn-text-color: #fff;
     --button-disabled-color: #e9ecef;
     --button-disabled-text-color: #64696d;
     

     
     /* Used Colors in sample page */
     --checkmaralert-color:#2196F3;
     --Applay-btn-color:#3399FF;
     --checked-color:#2196F3;
     --Accu-button-disabled-color-uat:#a6ca5c;
     --training-assign-check-color:#3399FF;
     --training-banner-color:#d9ebfe;
     --role-checked-color:#007bff;
     --mui-selected-color:rgba(14, 57, 99, 0.08);
     --datatable-checked-color:#3399FF;
     --mui-checked-color:#1976d2;
     --mui-switch-color:#FFF;  
     --managementhd-con-color: #E2E9D3;
     --selected-btn-color:#2196F3;
     --selected-btn-border-color:#2196F3;
     --Accu-assignunAssign-bg-color:#354089;
     --Accu-button-hov-color : #496A07;
     --Accu-button-color:#7FBC03;
     /* --Accu-button-color:#cef08a; */
     --Accu-button-disabled-color:#cdf381;
     --verticalline-color:#ffffff3b;
     --verticalline-color:#ffffff3b;
     --MuiAutocomplete-option-active-color :#dedede;
     --some-more-container-color:#0056b3;
     --acc-unit-ctn-bg-color:rgba(0,0,0,.05);
     --acc-pro-label-color:rgb(93, 93, 93);
     --acc-pro-ctn-color:rgb(184, 218, 255);
     --player-controls-border-color:#1E5799;
     --player-controls-shadow-color:#dddddd;
     --player-controls-bg-color:#8ce8f7;
     --slider-thumb-bg-color:#4096ee;
     --black-color:black;
     --section-header-text-color:#272727;
     --body-color:#272727;
     --product-start-now-btn-color: #272727;
     --primary-btn-color:#3399FF;
     --primary-hover-color: #006592;
     --primary-btn-text-color: #fff;
     --primary-btn-disable-bg-color: #C7E5FC;
     --secondarybtn-color:#8D9196;
     --secondarybtn-text-color:#fff;
     --secondarybtn-hover-color: #5a6268;
     --secondarybtn-disable-color: #BDC0C3;
     --danger-btn-delete-color:#D34848;
     --danger-btn-text-color: #fff;
     --error-text-color:#D34848;
     --label-text: #333;
     --general-text-color:#7E7B7B;
     --instruction-text-color: #020304;
     --button-disabled-color:#E0E3E6;
     --header-line-text: #0000001a;
     --text-active-color: #2da703;
     --assignunAssign-text-color:#e8e8e8;
     --assignunAssign-bg-color:#3d5d7a;
     --fafa-grp-icon-circle:#ff0000a6;
     --assignunAssign-label-color:#333;
     --li-text-color:#4e4e4e;
     --li-hov-color:#cecccc;
     --assignunassign-sub-text-color:#7d7d7d;
     --sso-btn-bg-color: #fff;
     --sso-box-shadow-color: #019ade69;
     --sso-btn-color: #71767b;
     --sso-hover-bg-color: #d1d1d147;
     --sso-hover-color: #019ade;
     --ac-right-arrow-color:#1a73e8;
     --dash-support-txt-color: #242424;
     --bs-link-color: rgb(13 110 253);
     --tab-inactive-text-color: rgba(0, 0, 0, 0.4);
     --view-message-btn-color: #3399FF;

     /* default */
     --dropdown-color: rgba(0, 0, 0, 0.54);
     /* --section-header-text-color: #031b4e; */
     --ed-pop-username-text-color: #313131;
     --modal-title-text-color: #272727;
     --modal-body-text-color: #716b6b;
     --ed-pop-heading-text-color:#031b4e; 
     --searchbar-border-color:  #ced4da;
     --searchbar-border-highlight-color: #007bff;
     --filterheader-border-color:#C7C7C7;
     --filterheader-background-color: #f2f1f1;
     --filtercontent-border-color:#f7f7f7;
     --filtercontent-background-color: #f7f7f7;
     --alert-header-text-color: #031b4e;
     --alert-background-color: #fff;
     --alert-border:  #0003;
     --alert-close-icon-color: #000;
     --alert-sub-text-color: #031b4e;
     /* --error-text-color: #F00; */
     --profile-text-color: #64696d;
     --input-text-color: #64696d;
     --input-placeholdertext-color: #CCC; 
     --product-card-box-shawdow: #CCC;
     --input-placeholder-disabled: #e9ecef;
     --label-text: #333;
     --instruction-text-color: #031b4e;
     --footer-text-color: #e6ebef;
     --close-button-color: #0003;
     --closebutton-shadow-color: #fff;
     --popup-cancel-button: rgb(108, 117, 125);
     --footer-text-color: #031b4e;
     --link-text-color: #007bff;
     --management-header-background-color: #E2F6FF;
     --nav-active-background-color: #f2f2f2;
     --training-course-unit-bg-color: #f2f2f2;
     --nav-active-parent-underline-color: #3399FF;
     --nav-active-subitem-underline-color: #5a6268;
     --checkbox-border-color:#c7cac9;
     --postsurvey-filedset-border-color: #c7cac9;
     --checkbox-hover-color:#ccc;
     --checkbox-checked-color: #2196f3;
     --search-icon-border:#ccc;
     --radio-mark2-background: #47b247;
     --button-default-color:#fff;
     --button-default-boder-color:#c7cac9;
     --close-icon-color: #6c757d;
     --primary-red:#FF0000;
     --primary-green: #008000;
     --download-icon-color: #b5b5b5;
     --drag-and-drop-background: #fcfcfc;
     --drag-and-drop-outline: #7E7B7B;
     --importStep-blue-color: #90d5f5;
     --importStep-grey-color: #d8d8d8;
     --importStep-green-color: #a5f06d;
     --importUser-progressBar-color1: #00f56d;
     --importUser-progressBar-color2: #3cb371;
     --importUser-successfully-recorder-text-color : #6DBB59;
     --custom-switch-background-color: #fff;
     --datatable-tablecell-color: #7E7B7B;
     --filter-hr-line: #e6e6e6;
     --close-icon: #b3b3b3;
     --datatable-header-text-color: #272727;
     --datatable-bg-header-color: #fff;
     --dataGrid-action-column-color: #007bff;
     --trainingManagement-capsule-bg-color : #808181;
     --trainingManagement-capsule-hover-color : #575757;
     --generalCourseNameCol-color-training:#edebeb;
     --primary-white: #fff;
     --surveyLink-bg-color: #f8f9fa;
     --fa-pause-color: #ff8100a6;
     --fa-check-circle-color: #00940094;
     --fa-times-color: #ff0000a6;
     --filterCom-selected-bg-color: #4992dc;
     --filterIcon-bg-color: #2ac0f9;
     --eligible-rows-selected-bg-color: #F2F1F1;
     --setCourseCompletion-ctn-boxshadow: #d7d8d9;
     --custom-control-btn-enabled: #00940094;
     --custom-control-btn-disabled: #ff0000a6;
     --filter-focus-btn-color: #007bff40;
     --primary-blue: blue;
     --inputfield-widget-bg-color: #e9ecef;
     --profile-box-shadow-color:  #0000003b;
     --product-card-img-bg-color: #f7f5f6;
     --capsuleList-Unit-Title-Color: #1240a3;
     --card-body-bg-color: #43a047;
     --my-refresher-capsules-border: #1FD6F3;
     --capsule-duration-color: #f7f3f3;
     --capsule-duration-bg-color: #3A3737;
     --capsule-desc-color: #423f3f;
     --capsule-status-color: #efa825;
     --primary-black: #000;
     --safestart-survey-checked-color:#505050;
     --autocomplete-input-text-color: #64696d;
     --cls-rpt-title-color: #023786;
     --cls-multi-chat-rpt: #f8f4f4;
     --refresher-capsulebootom-color :#1fd6f3;
     --mui-border-bottom: #dee2e6;
     --breadcrumbs-color: #757575;
     --resourse-filename-title-color: #005dab;
     --resourse-card-file-bg-color: #f1f4f7;
     --planning-ontrack-bg-color: #7db1f5;
     --layout-background-color: #E6EBEF;
     --classroom-fieldset-border: rgba(71, 71, 71, 0.35);
     --classroom-pq-completed-color: #43b055;
     --mui-close-icon: #716b6b;
     --mui-close-icon-hover: #000;
     --table-td-border-color: #c1c7cda1;
     --datatable-td-color: #5d5d5d;
     --datatable-iconBtn-color: rgba(0, 0, 0, 0.5);
     --footer-bg-color: #E6EBEF;
     --layout-bg-color: #E6EBEF;
     --coursepopup-frame-color-2: #e3f1fb;
     --coursepopup-frame-color-3: #6aaaff;
     --coursepopup-linear-grad-color-1: #fcfcfe;
     --coursepopup-linear-grad-color-2: #e2ecf8;
     --coursepopup-linear-grad-color-3: #b5d0ed;
     --coursepopup-bg-color: #696969;
     --gauge-chart-label: #716b6b;
     --card-cap-color: #00000040;
     --card-text-date-color: rgb(255, 7, 110);
     --card-text-para-color: #808080;
     --catagory-name-color: #D3D3D3;
     --product-img-ctn-color: #0000001a;
     --product-card-body-box-shadow-color: #00000080;
     --product-key-plus-box-shadow-color-1: #00000033;
     --product-key-plus-box-shadow-color-2: rgba(0, 0, 0, 0.14);
     --product-key-plus-box-shadow-color-3: rgba(0, 0, 0, 0.12);
     --capsule-list-img-hover: rgba(0, 0, 0, 0.25);
     --progress-bar-ctn-color-course: #D9D9D9;
     --progress-value-color-course: #41C043;
     --about-page-desc-color: #5c5c5c;
     --about-page-tabs-color: #2D2D2D;
     --about-tabs-active-text-color:#2196F3;
     --about-tabs-ctive-bg-color:#ECF6FF;
     --certificate-pop-active-tab-color: #B8DFFF;
     --certificate-pop-title-seperation-line: rgba(0, 0, 0, .1);
     --course-feedback-link-color: #2196F3;
     --transition-border-color: rgba(33, 150, 243, 0.5);
     --feedback-survey-slider-thumb-color : #2196F3;
     --course-banner-karate-empty-status-bs-color: #00000040;
     --course-banner-karate-empty-status-bg-color: #D2D2D266;
     /* --heder-line-text: #0000001a;  */
     /* --input-background-color:#333; */
       
     /* Dashboard */
     --rys-count-color:#A786E4;
     --dash-activeuser-color:#2DA703;
     --dash-inactiveuser-color:#D90000;
     --totallicenses-count-color:#5D5D5D;
     --highcharts-data-table-color: #ebebeb;
     --highcharts-data-table--caption-color: #555;
     --highcharts-data-table-thead-tr: #f8f8f8;
     --highcharts-data-table-tr-hover: #f1f7ff;
     --user-Active-text: #2d2d2d;
     --cardGuageFlex-h4-color: #716b6b;
     --fa-user-color: #ff990080;
     --fa-user-hover-color: #ff990099;
     --fa-user-bg-color: #ff99001a;
     --fa-user-bg-hover-color: #ff990033;
     --fa-smile-color: #5c23cc80;
     --fa-smile-hover-color: #5c23cc99;
     --fa-smile-bg-color: #5c23cc1a;
     --fa-smile-bg-hover-color: #5c23cc33;
     --scard-box-shadow-color-one: #00000033;
     --scard-box-shadow-color-two:#00000024;
     --scard-box-shadow-color-three: #0000001f;
     --dash-bannertext-color: #292929;


     /* Reports */
     --searchListBox-box-shadow-color-one: rgba(0, 0, 0, 0.12);
     --searchListBox-box-shadow-color-two: rgba(0, 0, 0, 0.14);
     --check-lbl-bg-color:rgb(207, 203, 203);
     --rpt-card-clipboard-icon: #0060d8;
     --rpt-card-name: #031b4e;
     --report-rowbox-border-color: orange;
     --reports-complete-background-color: #A6D486;
     --reports-complete-color: #2b2929;
     --reports-notstarted-background-color: #FF8F8F;
     --reports-notstarted-color: #2b2929;
     --reports-inprogress-background-color: #FFFF99;
     --rptlst-row-card-header-color: #d2d3d3;
     --course-list-item-type-color: #6386ea;
     --rptlst-list-border-color: #e9ecec;
     --rptlst-list-color: #656565;
     --report-role-access-head-bg-color: #007bff;
     --report-subtitle-color: #5a5a5a;
     --close-call-reports-cell-color: Lightgreen;
     --close-call-header-bg-color: #fdfdfd;
     --close-call-reports-sub-title-color: #4b72b5;
     --nearMiss-noImage-color: #cdcdcd;
     --close-call-reports-choose-file-color: #babaf7;
     --close-call-reports-close-icon-color: #f5f5f5;
     --MuiAlert-filledWarning-bg-color:#ffc46c;
     --MuiAlert-filledSuccess-bg-color: #7275c2;
     --border-bottom-close-call: #f3f1f1;
     --close-call-rpt-titlese: #e0e0e0;
     --close-call-rpt-subtitle: #5a5a5a;
     --close-call-rpt-back: #cdcdcd;
     --close-call-fade-color: #f6f3f3;
     --close-call-report-sel-bg-color: #c4c4c4;
     --clos-call-report-chart-type: #c9cbcd;
     --close-call-report-process-color: #d50000;
     --cls-rpt-item3: #F6F6F6;
     --cls-container-selection-border: #cdcdcd;
     --cls-rpt-sel-vals: #6b6bf6;
     --cls_sub_title1-color:#2f2599;
     --sub-container4-bg-color:#d6f1f6;
     --sub-container21-color:#686363;
     --sub_container23-color:#414040;
     --reports-ref-capsule-name-color: rgba(0, 0, 0, 0.87);
     --user-nav-box-shawdow-color: rgba(0, 0, 0, 0.09);
     --user-view-modal-selected-item-border: #808080;
   
     /* nearmiss close call  */
     --close-call-section-bg-color: #f5f5f5;
     --close-call-section-box-shawdow-color1: rgba(0, 0, 0, 0.2);
     --close-call-section-box-shawdow-color2: rgba(0, 0, 0, 0.14);
     --close-call-section-box-shawdow-color3: rgba(0, 0, 0, 0.12);
     --near-miss-sub-heade-color: #2751e9;
     --near-miss-grouping-border: #f3eded;
     --near-miss-self-equipment-border-color: #CDCDCD;
     --near-miss-self-equipment-bg-color: #F2F3F4;
     --near-miss-self-equipment-bg-hover-color: #C1EBBC;
     --near-miss-incident-title-focuse-color: #4b72b5;
     --near-miss-incident-place-card-color: rgba(0, 0, 0, 0.125);
     --near-miss-incident-place-card-checked-color: #C1EBBC;
     --near-miss-incident-place-card-checked-bg-color: rgba(0, 0, 0, 0.5);
     --near-miss-warn-msg-color: #a28b8b;
     --near-miss-choose-img-border-color: #babaf7;
     --near-miss-label-color: #64696d;
     --near-miss-close-icon-bg-color: #675a5a;
     --near-miss-close-icon-border-color: #f5f5f5;
     --near-miss-plus-icon-color: #f97b7b;
     --near-miss-search-border: #cdcdcd;
     --near-miss-search-bg-color: #f9f9f9;
     --near-miss-vertical-line-border: #ededed;
     --near-miss-attachment-max-list-color: #b34545;
     --near-miss-attachment-max-limit-color: rgb(26, 187, 120);
     --near-miss-top-color: #bbe6e8;
     --near-miss-bg-color: #00bcd4;
     --near-miss-card-border: rgba(0, 0, 0, 0.125);
     --near-miss-check-label-plc-color: #94f48a;

     /* Impress colors  */
     --player-controls-color:#2929297d;
     --impress-tabs-active-color: #5cadd2;
     --impress-tabs-active-radial-gradient-color: #43deed;
     --impress-tabs-border-color: #CDCDCD;
     --impress-datatable-header-bg-color: #DCF1FB;
     --impress-config-datatable-border-left: #80E3BA;
     --impress-config-email-border-ctn: #efebeb;
     --email-admin-user-title-color: #ede9e9;
     --email-admin-user-title-bg-color: #F1EFEF;
     --primary-black: #000000;
     --impress-config-task-top-border: #ede9e9;
     --checkbox-secondary-color: #007BFF;
     --checkbox-impress-checked-color: #b7b6b5;
     --impress-checkbox-border-color: #c7bea9;
     --impress-task-desc-bg-color: #ececec;
     --impress-create-template-color: #0d13de;
     --impress-disable-border-color: #aec7d3;
     --task-datatable-border-color: #eae97e;
     --impress-role-lable-color: #007bff;
     --task-setup-weight-one-bg-color: #CAEAE7;
     --task-setup-weight-two-bg-color: #EADECA;
     --task-setup-weight-three-bg-color: #D4CAEA;
     --task-setup-weight-four-bg-color: #EACAD5;
     --task-setup-weight-five-bg-color: #D5EACA;
     --add-task-weightage-color-two: #c21616;
     --task-table-cell-bg-color: #DCF1FB;
     --task-table-row-table-cell-bg-color: #f3f3f3;
     --task-table-row-table-cell: #5f5e5e;
     --task-table-row-table-cell-child2-bg-color: #f1ecec;
     --task-table-row-table-cell-child2-border-color: #dbd7d7;
     --task-add-new-member-color: #6c6cb7;
     --task-deadline-color: #CDCDCD;
     --sc-card-box-bg-color:#F0F0F6;
     --sc-card-color:#fdfcfc;
     --sc-card-content-color:#111;
     --team-name-txt-color:#292828;
     --cls-teamImage1-border-color:#edeaea;
     --cls-team-dtls-color:#f5f0f0;
     --create-tm-color:rgba(0, 0, 0, 0.05);
     --cls-header-container-border-color:#efeaea;
     --cls-add-new-member-color:#3333f3;
     --cls-error-border-color:#b9230f;
     --cls-add-lnk-color:#4a55f3;
     --checkplus-lbl-color:#001;
     --maincontainer-border-color:#f9fafb;
     --cls-max-size-color:#acafb5;
     --ontrack-bg-color:#7db1f5;
     --delayed-bg-color:#f5c947;
     --notstarted-txt-color:#c8c6c6;
     --completed-txt-color:#79da8d;
     --container-sf-set-shadow-color:#dbdada;
     --container-sf-set-border-color:#f4eded;
     --sub-container99-text-color:#615757;
     --sub-sf-container21:#535455;
     --sub-sf-container22-txt-color:#8f8f8f;
     --sub-sf-container23-txt-color :#5095d7;
     --MuiRadio-colorSecondary-color:#10d877;
     --sf-color1-bg-color:#f53f68;
     --sf-color2-bg-color:#00c8f4;
     --sf-color3-bg-color:#fdc400;
     --sf-color4-bg-color:#984bd6;
     --sf-color5-bg-color:#10d877;
     --planning-change-template-color: #007BFF;
     --planning-change-template-default-color: #babdbf;
     --successfactorts-change-temp-border-color: #e9e8e8;
     --select-base-data-color: #f77766;
     --planning-tableheader-bg-color: #DCF1FB;
     --planning-table-cell-bg-color: #f3f3f3;
     --planning-table-cell-color: #5f5e5e;
     --planning-table-cell-second-color: #f1ecec;
     --planning-table-cell-second-border-color: #dbd7d7;
     --checkplus-lbl-color:#64696d;
     --planning-matiral-card-hover-color:#579bfc;
     --impress-reset-img-color: red;
     --impress-main-task-color: #f7f4f4;
     --planning-mile-color: #eae97e;
     --progress-notstarted-color: #c8c6c6;
     --progress-ontrack-color: #7db1f5;
     --progress-delayed-color: #f5c947;
     --progress-completed-color: #79da8d;
     --planning-textarea-border-color: #e3dede;
     --planning-textarea-color: #716b6b;
     --dashboard-teams-text-color: #FFF;
     --dashboard-teams-border-color: #e90a0a;
     --dashboard-teams-box-shawdow-active-color:#40373766;
     --dashboard-datatable-header-bg-color: #86caea;
     --dashboard-Y1SF1-bg-color: #F53F68;
     --dashboard-Y1SF2-bg-color:#00C8F4;
     --dashboard-Y1SF3-bg-color:#FDC400;
     --dashboard-Y1SF4-bg-color:#984BD6;
     --dashboard-Y1SF5-bg-color:#10D877;
     --dashborad-checkplus-lbl-color: #64696d;
     --dashboard-admin-border-color: #CDCDCD;
     --dashboard-admin-bs-color1:#00000033;
     --dashboard-admin-bs-color2: #00000024;
     --dashboard-admin-bs-color3: #0000001f;
     --dashboard-admin-selected-border-color: #6980E6;
     --dashboard-admin-selected-bs-color: #e97878;
     --dashboard-back-status-color: #5f5fe9;
     --dashboard-impress-table-header-color: #262729;
     --dashboard-edit-icon-color: #6ec6ff;
     --dashboard-tabs-box-shadow: #eceaf3;
     --cls-dashboard-text-color:#af5979;
     --milestone-icon-color: #ea3a77;
     

     --cls-searching-button-color :#9f999991;
     --cls-searching-button-border-color :#cdc5c5;
     --sf-search-color:rgb(185, 32, 32);
     --cls-team-cross-color:#7f8ecf;
     --cls-legend-box-bg-color:#fb5a5a;
     --cls-act-inp-description-bg-color:#e3dede;
     --cls-resetall-color:rgb(65, 65, 238);
     --cls-ref-lnk-color:#4672d5;
     --cls-tabs-active-color:#0287EA;
     --cls-tabs-active-border-color:#0287EA;
     --cls-search-icon-color:#eee;
     --MuiChip-root-bg-color:#f0f6ff;
     --MuiChip-root-border-color :#a7adb1;
     --popup-bg-color:rgba(0,0,0, 0.5);

     /* certificate colors  */
     --printer-color: #fff;
     --certificate-body-color: #fff;
     --printer-color-icon-color: #000000;
     --certificate-title-color: #333333;
     --certificate-name-color: #49518e;
     --accutrain-certificate-text-color: #354089;

     /* loginpage */
      --email-password-border-bottom: #c4c4c4;
      --email-password-text-color: #d1cece;
      --email-login-or-text-color: #969595;
      --email-login-or-border-color: #f1f0f0;
      --email-password-text-after-color: #919191;
      --forgot-password-back-icon: #505050;
      --forgot-password-back-text-color: #007bff;
      --username-password-placeholder-color: #ced4da;
      --forgot-password-authentication-bg-color: #dc3545;
      --change-password-here-label-color: rgba(0, 0, 0, 0.6);

     /* header  */
     --header-profile-drp-color: #6c757d;
     --header-hamburger-img-bg-color: #e7eef3;
     --header-profile-drp-active-color: #2d2d2d;
     --header-nav-link-border-color: #d2d7dc;
     --header-profile-ios-border-top-color: #e5e4e1;
     --header-search-icon-color: #6c757d;
     --header-navbar-color: #E6EBEF;
   
     /* --header-navbar-box-shawdow-color1:  */

     /* Sidebar */
     --sidebar-text-color: #fff;
     --sidebar-select-side-background-color: #019ade;
     --sidebar-background-color: #2d2d2d;
     --sidebar-background-shadow-color: 0 1px 8px 0 #0003, 0 3px 4px 0 #00000024, 0 3px 3px -2px #0000001f; 
     --sidehead-link-color:rgba(255, 255, 255, 0.6);
     --sideheadspan-color:#ffffff99;
     --align-i-color:rgba(255, 255, 255, 0.6);
     --sidehead-span-color:#484848;
     --sidebartopic-color:#5dd5e8;
     --sidebarpannel-color:#364760;
     --sidebarpannel-span-color:#f3f3f3;
     --sidegead-hover-color:#000000;
     --active-bg-color:rgba(0, 0, 0, 0.3);
     --actived-bg-color:#0000004d;
     --webkit-tap-highlight-color:rgba(0, 0, 0, 0);
     --form-control-webkit-color:rgba(100, 105, 109, 0.55);
     --active-menu-side-border-color: #3399FF;

     /* setting */
     --settings-sub-head-color: #031b4e;
     --settings-col-left-text: #333;
     --settings-col-right-text: #031b4e;
     --setting-editlogo-text-color: #007bff;
     --form-group-required-after-color:red;
     --cls-check-group-top-color:#CDCDCD;
     --capsulesheader-bg-color:#818181;

     /* dashboard */
     --dashboard-header-text-color: #333;
     --dashboard-sub-header-text-color: #64696d;
     --dashboard-actuser-icon-color: #ff990080;
     --dashboard-actuser-bg-icon-color: #ff99001a;
     --dashboard-actuser-text-color: #000;
     --dashboard-totalrys-icon-color: #5c23cc80;
     --dashboard-totalrys-bg-icon-color: #5c23cc1a;
     --dashboard-totalrys-text-color: #000;

     /* userlist */
     --user-filter-header-text-color: #031b4e;
     --user-filter-icon-color: #031b4e;
     --user-filter-subheader-text-color: #716b6b;
     --user-filter-text-dropdown-color: #031b4e;
     --userlist-table-heading-color: rgba(0, 0, 0, 0.87);
     --userlist-table-datas-color: #716b6b;
     --userlist-table-active-color: #2da703;
     --userlist-table-inactive-color: #d90000;
     --userlist-table-edit-color: #007bff;
     --userlist-table-delete-color: #007bff;
     --userlist-table-reverify-color: #007bff;
     --userlist-selected-button-bg-color: rgb(33, 150, 243);
     --userlist-selected-button-border-color: rgb(33, 150, 243);
     --userlist-selected-button-text-color: gb(255, 255, 255);
     --userlist-count-button-color: #2196f3;
     --userlist-count-button-text-color: :#fff;

     /* grouplist */
     --grouplist-table-heading-color: rgba(0, 0, 0, 0.87);
     --grouplist-table-datas-color: #716b6b;
     --grouplist-table-active-color: #2da703;
     --grouplist-table-inactive-color: #d90000;
     --grouplist-table-edit-color: #007bff;
     --grouplist-table-delete-color: #007bff;
     --grouplist-table-reverify-color: #007bff;
     --grouplist-selected-text-color: #000;
     --grouplist-sub-heading-text-color: #333;

     /* Assignment */
     --Assignment-sub-heading-color: #1e1e1e;
     --Assignment-table-heading-color: rgba(0, 0, 0, 0.87);
     --Assignment-table-datas-color: #716b6b;
     --Assignment-table-edit-color: #007bff;

     /* Recordcompletion */
     --rc-sub-heading-text-color: #333;
     --rc-filter-header-text-color: #031b4e;
     --rc-filter-icon-color: #031b4e;
     --rc-filter-subheader-text-color: #716b6b;
     --rc-filter-text-dropdown-color: #031b4e;
     --rc-table-heading-color: rgba(0, 0, 0, 0.87);
     --rc-table-datas-color: #716b6b;

     /* Reset Training */
     --rt-sub-heading-text-color: #333;
     --rt-filter-icon-color: #031b4e;
     --rt-table-heading-color: rgba(0, 0, 0, 0.87);
     --rt-table-datas-color: #716b6b;
     --player-controls-shadow-color:rgba(0,0,0,0.4);

     /* Toolbox */
     --toolbox-sub-head-color: #496077;
     --toolbox-arrow-color: #031b4e;
     --modal-content-color:rgba(0, 0, 0, 0.5);
     --popup-header-fname-color:#ffffff;
     --cucu-btn-color:rgba(0, 0, 0, 0.4);
     --filename-color:#04358d;
     --fdr-str-hover-color:#47a1ef;
     --fdr-view-color:#fdfdfd;
     --lsthdr-color:#c2c1c1;
     --fileview-color:#edebeb;
     --fileview-hover-color:#f8f7f7;
     --fileView-color:#c9c2c2;

      /* Rate your state */
      --row-ctn-color:rgb(251, 251, 251);
      --rate-sub-box-bg-color: #c4cbd4;
      --rate-sub-card-i-color:#018001;
      --rateinfo-color:#e91e63;
      --rate-disable-color:rgba(113, 113, 113, 0.4);
      --rys-text-color:#000;
      --checkmark-img-color:rgba(0, 0, 0, 0.1);
     --ssi-rys-qa-cardbd-bg-opts:#0099ff;
     --rate-ifield-text-brdclr-color:#00ffff;
     --input-range-slider:#3f51b5;
     --input-range-slider-color:#888888;
     --input-range-label-color:#031b4e;
     --ebkit-scrollbar-track-bg-color:#f1f1f1;
     --webkit-scrollbar-thumb:#b1b1b1;
     --webkit-scrollbar-thumb-hover-color:#555;
     --qa-rate-board-usr-radio:#f7f7f7;
     --rate-dp-bg-color:#e4e4e4 ;
     --react-date-picker-bg-color:#e0e0e0;
     --white-bg-color:white;
     --label-check-color:rgba(0, 0, 0, 0.5);
     --label-check-hovor-color:rgba(0, 0, 0, 0.2);
     --label-text-label-check:#00d478;

     /* Survey Updated  */
     --checked-label-color:#2196f3;
     --range-value-color:#2196f3;
     --textarea-bg-color: #D3EAFD;
     --progress-bar-ctn-color:#d3eafd;
     --previous-btn-bg-color: rgba(0, 0, 0, 0.40);


     /* Steering commitee */
     --sc-sub-heading-color: #757575;

     /* Storyfeeds */
     --imageCloseBtn-bg-color:#6d6969;
     --sf-createpost-bg-color: #dfdfdf;
     --sf-createpost-text-color: #4277ec;
     --sf-username-text-color: #505050;
     --sf-hours-color: #8e8e8e;
     --sf-profile-text-color: #fff;
     --sf-profile-bg-color: #4f8dc5;
     --sf-subheading-color: #031b4e;
     --sf-icons-color: #b2b2b3;
     --sf-post-text-color: #019ade;
     --sf-channels-text-color: #019ade;
     --sf-sub-head-color: #212529;
     --sf-channels-sub-head-color: #878585;
     --sf-your-channel-text-color: #007bff;
     --sf-feedsPostContainer-color:#cccccc;
     --sf-feedstHeader-color:#737373;
     --channelListInPost-color:#ababab;
     --postChannelListDropdown-placeholder:#818080;
     --sf-readContaineNoFeedData-color:#9f0303;
     --sf-feedsPostEmojiHolder-border:#f2f87e;
     --sf-feedsPostEmojiHolder-bg-color:#0707e9;
     --sf-feedsStory-color:#797979;
     --sf-feedsChannelNavStrip-hover:#f8f9f9;
     --sf-feedsChannelItemBtn-color:#969494;
     --sf-feedsChannelItemBtn-hover-color:#3f456f;
     --sf-alertContainerWrapper-color:rgba(149, 146, 146, 0.5);
     --sf-channelName-color :#5f5f5f;
     --sf-morePopupMenuItem-hover:#f3f6f8;
     --sf-formContainer-bg-color:rgba(100, 100, 100, 0.6);
     --sf-fomButtonContainer-bg-color:#767676;
     --sf-legendsIcon-color:#ddd;
     --sf-userListLegends-color:#c7c9ca;
     --userListAssigned-hover:#007fb7;
     --sf-channelHeaderName-color:#424040;
     --sf-profileRole-color:#a0a0a0;
     --sf-descriptionBlockContent-color:#9a9a9a;
     --sf-chnnelDropdown-border-color:#0088c5;
     --sf-displayBlock-color:#e5e4e1;
     --sf-removeicon-color:#8c8c8c;
     --sf-TextHolder-color:#efefef;
     --sf-ContentOverFlowWrapper-color:#493fe3;
     --sf-displayBlockLikeBtn-color:#8080b0;
     --sf-feedLiked-color:#1485bd;
     --sf-displayBlockComments-color:hsla(0, 0%, 96.9%, 0.5);
     --sf-displayBlockLike-color:#959697;
     --sf-feedsLoadingContainer-color:rgba(189, 189, 189, 0.5);
     --fa-list-color:rgba(1, 154, 222, 0.2);
     --fa-col-color:#9E9E9E;
     --fa-pc-h5-color:rgba(0,0,0,.1);
     --fa-trash-alt-color:#fd7e14;
     --fa-check-square-bg-color:#15aabf;
     --sf-imageCloseBtn-color:#363636;
     --sf-imageCloseBtn-bg-color:rgba(255, 255, 255, 0.8);
     --sf-input-box-color:lightgray;
     --sf-modal-two-color:#34373b;
     --sf-input-box-text-color:#b8b8b8;
     --sf-cool-link-color:skyblue;
     --feedsPostHeader-color:#f7f5f5;
     --displayBlockRemoveBtnPannel-color:#707070;
     --sf-assertsViewerHeaderContaner-color:#1e1c1c;
     --assertsViewerContentContaner-color:#121212;
     --assetsViewer-btn-hover:#939adf;
     --postFileHolder-color:#e5e5e7;
     --FileTag-color:#59595a;
     --FileCloseBtn-color:#37379c;
     --process-cover-color:#007bff2b;
     --stripContainer-color:#585858;
     --deleteContainer-color:#a9a9a9;

    /* Closecall */
     --cc-question-text-color: #2751e9;
     --cc-sub-head-color: rgba(0, 0, 0, 0.54);
     --cc-incident-sub-head-color: #4b72b5;
     --cc-source-button-bg-color: #c1ebbc;
     --cc-source-button-text-color: #000;
     --cc-severity-button-text-color: ?;
     --cc-severity-button-border-color: solid rgb(166, 165, 165);
     --cc-severity-serious-button-bg-color: rgb(192, 0, 0);
     --cc-severity-major-button-bg-color: rgb(252, 2, 1);
     --cc-severity-minor-button-bg-color: rgb(249, 125, 42);
     --cc-severity-low-button-bg-color: rgb(251, 232, 5);
     --cc-severity-verylow-button-bg-color: rgb(146, 208, 81);
     --cc-chooseimg-text-color: #007bff;
     --cc-filter-icon-color: #212529;

     /* Reports */
     --rpt-sub-head-text-color: #031b4e;
     --rpt-filterbar-bg-color: white;
     --rpt-filterbar-border-color: rgba(0, 0, 0, 0.12);
     --rpt-filter-icon-color: #212529;
     --rpt-popup-columns-text-color: #64696d;
     --rpt-popup-subhead-text-color: #031b4e;
     --rpt-popup-filter-searchbar-text-color: rgb(100, 105, 109);
     --rpt-popup-filter-searchbar-border: rgb(206, 212, 218);
     --rpt-popup-more-text-color: blue;
     --rpt-popup-filter-selectedbar-color: solid grey;
     --rpt-export-prop-color: #212529;
     --rpt-table-upper-heading-text-color: #031b4e;
     --rpt-table-heading-text-color: rgba(0, 0, 0, 0.87);
     --rpt-table-datas-text-color: #716b6b;
     --rpt-table-active-color: #2da703;
     --rpt-table-inactive-color: #d90000;
     --rpt-tbl-upperhead-lefttext-color: #031b4e;
     --rpt-tbl-upperhead-righttext-color: blue;
     --searchlistbox-cust-report-border-color: #0000001f;
     --rys-conversation-records-ctn-color: #3399FF4D;
     --rys-total-supervisor-count-color: #3399FF;
     --rys-supervisor-count-color: #5D5D5D;
     --rys-conversation-blue-border: #39F;
     --rys-conversation-rank-text-color:#39F;
     --rys-conv-top-rank-bg-color:#3D9CFB;
     /* Learner progress*/
     --lp-chart-number-color: #2d2d2d;
     --lp-chart-text-color: #031b4e;

     /* Learner Course Progress */
     --lcp-course-col-bg-color: #b8daff;
     --lcp-unit-col-bg-color: #0000000d;

     /* Historical Learner Courses */
     --hlc-popup-datebar-color: #e9ecef;
     --hlc-popup-datebar-text-color: #64696d;

     /* Refresher Capsule Summary */
     --rfs-course-col-bg-color: #b8daff;
     --rfs-col-header-text-color: #5d5d5d;
     --rfs-unit-col-bg-color: #0000000d;

     /* Risk Pattern */
     --rp-sub-head-color: #000;
     --rp-question-text-color: #1a3598;
     --rp-qus-sub-text-color: inherit; 

     /* Survey Management */
     --qr-steps-bg-color: #F2F1F1;
     --guide-qr-label-color: #3399FF;
     --survey-setting-tab-hr-color: #3399FF;
     --survey-settings-input-border: #3399FF;
     --poster-survey-qr-code: #007EE0;
     --link-tooltip-color: #333;
}
.green {
     /* button */
     /* --primary-btn-color: #007bff;
      --primary-btn-text-color: #fff;
     --primary-btn-hover-color: #006592;
     --primary-btn-hover-text-color: #fff;
     --primary-btn-active-color: #8cc1fa;
     --primary-btn-active-text-color: #ffffffF;
     --primary-btn-disable-color: #5a6268;
     --primary-btn-disable-text-color: #fff;
     --primary-btn-disable-border-color: #545b62;
     --danger-btn-delete-color: #e95454;
     --primary-btn-Assign-color: #3d5d7a;
     --primary-btn-Assign-color: #fff;
     --secondarybtn-color: #6c757d;
     --secondarybtn-text-color: #fff;
     --danger-btn-text-color: #fff;
     --button-disabled-color: #e9ecef;
     --button-disabled-text-color: #64696d;

     /* Used Colors in sample page */
     --checkmaralert-color:#7FBC03;
     --Applay-btn-color:#7FBC03;
     --checked-color:#7FBC03;
     --Accu-button-disabled-color-uat:#a6ca5c;
     --Accu-button-disabled-color:#d9f5a2;
     --training-assign-check-color:#7FBC03;
     --training-banner-color:#d9ebfe;
     --role-checked-color:#7FBC03;
     --mui-selected-color:#E7F2D3;
     --datatable-checked-color:#7FBC03;
     --mui-checked-color:#7FBC03;
     --mui-switch-color:#fff;
     /* --mui-checked-color:#1976d2; */
     --managementhd-con-color:#DFEEC0;
     --selected-btn-color:#2196F3;
     --selected-btn-border-color:#2196F3;
     --Accu-assignunAssign-bg-color:#354089;
     --Accu-button-hov-color : #496A07;
     --Accu-button-color:#7FBC03;
     --verticalline-color:#ffffff3b;
     --MuiAutocomplete-option-active-color :#dedede;
     --some-more-container-color:#0056b3;
     --acc-unit-ctn-bg-color:rgba(0,0,0,.05);
     --acc-pro-label-color:rgb(93, 93, 93);
     --acc-pro-ctn-color:rgb(184, 218, 255);
     --player-controls-border-color:#1E5799;
     --player-controls-shadow-color:#dddddd;
     --player-controls-bg-color:#8ce8f7;
     --slider-thumb-bg-color:#4096ee;
     --black-color:black;
     --section-header-text-color:#272727;
     --body-color:#272727;
     --product-start-now-btn-color: #272727;
     --primary-btn-color:#7fbc03;
     --primary-hover-color: #006592;
     --primary-btn-text-color: #fff;
     --primary-btn-disable-bg-color:#cef08a;
     --secondarybtn-color:#8D9196;
     --secondarybtn-text-color:#fff;
     --secondarybtn-hover-color: #5a6268;
     --secondarybtn-disable-color: #BDC0C3;
     --danger-btn-delete-color:#D34848;
     --danger-btn-text-color: #fff;
     --error-text-color:#D34848;
     --label-text: #333;
     --general-text-color:#7E7B7B;
     --instruction-text-color: #020304;
     --button-disabled-color:#E0E3E6;
     --header-line-text: #0000001a;
     --text-active-color: #2da703;
     --assignunAssign-text-color:#e8e8e8;
     --assignunAssign-bg-color:#3d5d7a;
     --fafa-grp-icon-circle:#ff0000a6;
     --assignunAssign-label-color:#333;
     --li-text-color:#4e4e4e;
     --li-hov-color:#cecccc;
     --assignunassign-sub-text-color:#7d7d7d;
     --sso-btn-bg-color: #fff;
     --sso-box-shadow-color: #019ade69;
     --sso-btn-color: #71767b;
     --sso-hover-bg-color: #d1d1d147;
     --sso-hover-color: #019ade;
     --ac-right-arrow-color:#1a73e8;
     --dash-support-txt-color: #242424;
     --bs-link-color: rgb(13 110 253);
     --tab-inactive-text-color: rgba(0, 0, 0, 0.4);
     --view-message-btn-color: #3399FF;

     /* default */
     --dropdown-color: rgba(0, 0, 0, 0.54);
     /* --section-header-text-color: #031b4e; */
     --ed-pop-username-text-color: #313131;
     --modal-title-text-color: #272727;
     --modal-body-text-color: #716b6b;
     --ed-pop-heading-text-color:#031b4e; 
     --searchbar-border-color:  #ced4da;
     --searchbar-border-highlight-color:#7FBC03;
     --filterheader-border-color:#C7C7C7;
     --filterheader-background-color: #f2f1f1;
     --filtercontent-border-color:#f7f7f7;
     --filtercontent-background-color: #f7f7f7;
     --alert-header-text-color: #031b4e;
     --alert-background-color: #fff;
     --alert-border:  #0003;
     --alert-close-icon-color: #000;
     --alert-sub-text-color: #031b4e;
     /* --error-text-color: #F00; */
     --profile-text-color: #64696d;
     --input-text-color: #64696d;
     --input-placeholdertext-color: #CCC; 
     --product-card-box-shawdow: #CCC;
     --input-placeholder-disabled: #e9ecef;
     --label-text: #333;
     --instruction-text-color: #031b4e;
     --footer-text-color: #e6ebef;
     --close-button-color: #0003;
     --closebutton-shadow-color: #fff;
     --popup-cancel-button: rgb(108, 117, 125);
     --footer-text-color: #031b4e;
     --link-text-color: #007bff;
     --management-header-background-color: #E2F6FF;
     --nav-active-background-color: #f2f2f2;
     --training-course-unit-bg-color: #f2f2f2;
     --nav-active-parent-underline-color: #3399FF;
     --nav-active-subitem-underline-color: #5a6268;
     --checkbox-border-color:#c7cac9;
     --postsurvey-filedset-border-color: #c7cac9;
     --checkbox-hover-color:#ccc;
     --checkbox-checked-color: #2196f3;
     --search-icon-border:#ccc;
     --radio-mark2-background: #47b247;
     --button-default-color:#fff;
     --button-default-boder-color:#c7cac9;
     --close-icon-color: #6c757d;
     --primary-red:#FF0000;
     --primary-green: #008000;
     --download-icon-color: #b5b5b5;
     --drag-and-drop-background: #fcfcfc;
     --drag-and-drop-outline: #7E7B7B;
     --importStep-blue-color: #90d5f5;
     --importStep-grey-color: #d8d8d8;
     --importStep-green-color: #a5f06d;
     --importUser-progressBar-color1: #00f56d;
     --importUser-progressBar-color2: #3cb371;
     --importUser-successfully-recorder-text-color : #6DBB59;
     --custom-switch-background-color: #fff;
     --datatable-tablecell-color: #7E7B7B;
     --filter-hr-line: #e6e6e6;
     --close-icon: #b3b3b3;
     --datatable-header-text-color: #272727;
     --datatable-bg-header-color: #fff;
     --dataGrid-action-column-color: #007bff;
     --trainingManagement-capsule-bg-color : #808181;
     --trainingManagement-capsule-hover-color : #575757;
     --generalCourseNameCol-color-training:#edebeb;
     --primary-white: #fff;
     --surveyLink-bg-color: #f8f9fa;
     --fa-pause-color: #ff8100a6;
     --fa-check-circle-color: #00940094;
     --fa-times-color: #ff0000a6;
     --filterCom-selected-bg-color: #7FBC03;
     --filterIcon-bg-color: #2ac0f9;
     --eligible-rows-selected-bg-color: #F2F1F1;
     --setCourseCompletion-ctn-boxshadow: #d7d8d9;
     --custom-control-btn-enabled: #00940094;
     --custom-control-btn-disabled: #ff0000a6;
     --filter-focus-btn-color:#7FBC03;
     --primary-blue: blue;
     --inputfield-widget-bg-color: #e9ecef;
     --profile-box-shadow-color:  #0000003b;
     --product-card-img-bg-color: #f7f5f6;
     --capsuleList-Unit-Title-Color: #1240a3;
     --card-body-bg-color: #43a047;
     --my-refresher-capsules-border: #1FD6F3;
     --capsule-duration-color: #f7f3f3;
     --capsule-duration-bg-color: #3A3737;
     --capsule-desc-color: #423f3f;
     --capsule-status-color: #efa825;
     --primary-black: #000;
     --safestart-survey-checked-color:#505050;
     --autocomplete-input-text-color: #64696d;
     --cls-rpt-title-color: #023786;
     --cls-multi-chat-rpt: #f8f4f4;
     --refresher-capsulebootom-color :#1fd6f3;
     --mui-border-bottom: #dee2e6;
     --breadcrumbs-color: #757575;
     --resourse-filename-title-color: #005dab;
     --resourse-card-file-bg-color: #f1f4f7;
     --planning-ontrack-bg-color: #7db1f5;
     --layout-background-color: #E6EBEF;
     --classroom-fieldset-border: rgba(71, 71, 71, 0.35);
     --classroom-pq-completed-color: #43b055;
     --mui-close-icon: #716b6b;
     --mui-close-icon-hover: #000;
     --table-td-border-color: #c1c7cda1;
     --datatable-td-color: #5d5d5d;
     --datatable-iconBtn-color: rgba(0, 0, 0, 0.5);
     --footer-bg-color: #E6EBEF;
     --layout-bg-color: #E6EBEF;
     --coursepopup-frame-color-2: #e3f1fb;
     --coursepopup-frame-color-3: #6aaaff;
     --coursepopup-linear-grad-color-1: #fcfcfe;
     --coursepopup-linear-grad-color-2: #e2ecf8;
     --coursepopup-linear-grad-color-3: #b5d0ed;
     --coursepopup-bg-color: #696969;
     --gauge-chart-label: #716b6b;
     --card-cap-color: #00000040;
     --card-text-date-color: rgb(255, 7, 110);
     --card-text-para-color: #808080;
     --catagory-name-color: #D3D3D3;
     --product-img-ctn-color: #0000001a;
     --product-card-body-box-shadow-color: #00000080;
     --product-key-plus-box-shadow-color-1: #00000033;
     --product-key-plus-box-shadow-color-2: rgba(0, 0, 0, 0.14);
     --product-key-plus-box-shadow-color-3: rgba(0, 0, 0, 0.12);
     --capsule-list-img-hover: rgba(0, 0, 0, 0.25);
     --progress-bar-ctn-color-course: #D9D9D9;
     --progress-value-color-course: #41C043;
     --about-page-desc-color: #5c5c5c;
     --about-page-tabs-color: #2D2D2D;
     --about-tabs-active-text-color:#2196F3;
     --about-tabs-ctive-bg-color:#ECF6FF;
     --certificate-pop-active-tab-color: #B8DFFF;
     --certificate-pop-title-seperation-line: rgba(0, 0, 0, .1);
     --course-feedback-link-color: #2196F3;
     --transition-border-color: rgba(33, 150, 243, 0.5);
     --feedback-survey-slider-thumb-color : #2196F3;
     --course-banner-karate-empty-status-bs-color: #00000040;
     --course-banner-karate-empty-status-bg-color: #D2D2D266;
     /* --heder-line-text: #0000001a;  */
     /* --input-background-color:#333; */
       
     /* Dashboard */
     --rys-count-color:#A786E4;
     --dash-activeuser-color:#2DA703;
     --dash-inactiveuser-color:#D90000;
     --totallicenses-count-color:#5D5D5D;
     --highcharts-data-table-color: #ebebeb;
     --highcharts-data-table--caption-color: #555;
     --highcharts-data-table-thead-tr: #f8f8f8;
     --highcharts-data-table-tr-hover: #f1f7ff;
     --user-Active-text: #2d2d2d;
     --cardGuageFlex-h4-color: #716b6b;
     --fa-user-color: #ff990080;
     --fa-user-hover-color: #ff990099;
     --fa-user-bg-color: #ff99001a;
     --fa-user-bg-hover-color: #ff990033;
     --fa-smile-color: #5c23cc80;
     --fa-smile-hover-color: #5c23cc99;
     --fa-smile-bg-color: #5c23cc1a;
     --fa-smile-bg-hover-color: #5c23cc33;
     --scard-box-shadow-color-one: #00000033;
     --scard-box-shadow-color-two:#00000024;
     --scard-box-shadow-color-three: #0000001f;
     --dash-bannertext-color: #292929;


     /* Reports */
     --searchListBox-box-shadow-color-one: rgba(0, 0, 0, 0.12);
     --searchListBox-box-shadow-color-two: rgba(0, 0, 0, 0.14);
     --check-lbl-bg-color:rgb(207, 203, 203);
     --rpt-card-clipboard-icon: #0060d8;
     --rpt-card-name: #031b4e;
     --report-rowbox-border-color: orange;
     --reports-complete-background-color: #A6D486;
     --reports-complete-color: #2b2929;
     --reports-notstarted-background-color: #FF8F8F;
     --reports-notstarted-color: #2b2929;
     --reports-inprogress-background-color: #FFFF99;
     --rptlst-row-card-header-color: #d2d3d3;
     --course-list-item-type-color: #6386ea;
     --rptlst-list-border-color: #e9ecec;
     --rptlst-list-color: #656565;
     --report-role-access-head-bg-color: #007bff;
     --report-subtitle-color: #5a5a5a;
     --close-call-reports-cell-color: Lightgreen;
     --close-call-header-bg-color: #fdfdfd;
     --close-call-reports-sub-title-color: #4b72b5;
     --nearMiss-noImage-color: #cdcdcd;
     --close-call-reports-choose-file-color: #babaf7;
     --close-call-reports-close-icon-color: #f5f5f5;
     --MuiAlert-filledWarning-bg-color:#ffc46c;
     --MuiAlert-filledSuccess-bg-color: #7275c2;
     --border-bottom-close-call: #f3f1f1;
     --close-call-rpt-titlese: #e0e0e0;
     --close-call-rpt-subtitle: #5a5a5a;
     --close-call-rpt-back: #cdcdcd;
     --close-call-fade-color: #f6f3f3;
     --close-call-report-sel-bg-color: #c4c4c4;
     --clos-call-report-chart-type: #c9cbcd;
     --close-call-report-process-color: #d50000;
     --cls-rpt-item3: #F6F6F6;
     --cls-container-selection-border: #cdcdcd;
     --cls-rpt-sel-vals: #6b6bf6;
     --cls_sub_title1-color:#2f2599;
     --sub-container4-bg-color:#d6f1f6;
     --sub-container21-color:#686363;
     --sub_container23-color:#414040;
     --reports-ref-capsule-name-color: rgba(0, 0, 0, 0.87);
     --user-nav-box-shawdow-color: rgba(0, 0, 0, 0.09);
     --user-view-modal-selected-item-border: #808080;
     --rys-conversation-records-ctn-color: #3399FF4D;
     --rys-total-supervisor-count-color: #3399FF;
     --rys-supervisor-count-color: #5D5D5D;
     --rys-conversation-blue-border: #39F;
     --rys-conversation-rank-text-color:#39F;
     --rys-conv-top-rank-bg-color:#3D9CFB;
   
     /* nearmiss close call  */
     --close-call-section-bg-color: #f5f5f5;
     --close-call-section-box-shawdow-color1: rgba(0, 0, 0, 0.2);
     --close-call-section-box-shawdow-color2: rgba(0, 0, 0, 0.14);
     --close-call-section-box-shawdow-color3: rgba(0, 0, 0, 0.12);
     --near-miss-sub-heade-color: #2751e9;
     --near-miss-grouping-border: #f3eded;
     --near-miss-self-equipment-border-color: #CDCDCD;
     --near-miss-self-equipment-bg-color: #F2F3F4;
     --near-miss-self-equipment-bg-hover-color: #C1EBBC;
     --near-miss-incident-title-focuse-color: #4b72b5;
     --near-miss-incident-place-card-color: rgba(0, 0, 0, 0.125);
     --near-miss-incident-place-card-checked-color: #C1EBBC;
     --near-miss-incident-place-card-checked-bg-color: rgba(0, 0, 0, 0.5);
     --near-miss-warn-msg-color: #a28b8b;
     --near-miss-choose-img-border-color: #babaf7;
     --near-miss-label-color: #64696d;
     --near-miss-close-icon-bg-color: #675a5a;
     --near-miss-close-icon-border-color: #f5f5f5;
     --near-miss-plus-icon-color: #f97b7b;
     --near-miss-search-border: #cdcdcd;
     --near-miss-search-bg-color: #f9f9f9;
     --near-miss-vertical-line-border: #ededed;
     --near-miss-attachment-max-list-color: #b34545;
     --near-miss-attachment-max-limit-color: rgb(26, 187, 120);
     --near-miss-top-color: #bbe6e8;
     --near-miss-bg-color: #00bcd4;
     --near-miss-card-border: rgba(0, 0, 0, 0.125);
     --near-miss-check-label-plc-color: #94f48a;

     /* Impress colors  */
     --player-controls-color:#2929297d;
     --impress-tabs-active-color: #5cadd2;
     --impress-tabs-active-radial-gradient-color: #43deed;
     --impress-tabs-border-color: #CDCDCD;
     --impress-datatable-header-bg-color: #DCF1FB;
     --impress-config-datatable-border-left: #80E3BA;
     --impress-config-email-border-ctn: #efebeb;
     --email-admin-user-title-color: #ede9e9;
     --email-admin-user-title-bg-color: #F1EFEF;
     --primary-black: #000000;
     --impress-config-task-top-border: #ede9e9;
     --checkbox-secondary-color: #007BFF;
     --checkbox-impress-checked-color: #b7b6b5;
     --impress-checkbox-border-color: #c7bea9;
     --impress-task-desc-bg-color: #ececec;
     --impress-create-template-color: #0d13de;
     --impress-disable-border-color: #aec7d3;
     --task-datatable-border-color: #eae97e;
     --impress-role-lable-color: #007bff;
     --task-setup-weight-one-bg-color: #CAEAE7;
     --task-setup-weight-two-bg-color: #EADECA;
     --task-setup-weight-three-bg-color: #D4CAEA;
     --task-setup-weight-four-bg-color: #EACAD5;
     --task-setup-weight-five-bg-color: #D5EACA;
     --add-task-weightage-color-two: #c21616;
     --task-table-cell-bg-color: #DCF1FB;
     --task-table-row-table-cell-bg-color: #f3f3f3;
     --task-table-row-table-cell: #5f5e5e;
     --task-table-row-table-cell-child2-bg-color: #f1ecec;
     --task-table-row-table-cell-child2-border-color: #dbd7d7;
     --task-add-new-member-color: #6c6cb7;
     --task-deadline-color: #CDCDCD;
     --sc-card-box-bg-color:#F0F0F6;
     --sc-card-color:#fdfcfc;
     --sc-card-content-color:#111;
     --team-name-txt-color:#292828;
     --cls-teamImage1-border-color:#edeaea;
     --cls-team-dtls-color:#f5f0f0;
     --create-tm-color:rgba(0, 0, 0, 0.05);
     --cls-header-container-border-color:#efeaea;
     --cls-add-new-member-color:#3333f3;
     --cls-error-border-color:#b9230f;
     --cls-add-lnk-color:#4a55f3;
     --checkplus-lbl-color:#001;
     --maincontainer-border-color:#f9fafb;
     --cls-max-size-color:#acafb5;
     --ontrack-bg-color:#7db1f5;
     --delayed-bg-color:#f5c947;
     --notstarted-txt-color:#c8c6c6;
     --completed-txt-color:#79da8d;
     --container-sf-set-shadow-color:#dbdada;
     --container-sf-set-border-color:#f4eded;
     --sub-container99-text-color:#615757;
     --sub-sf-container21:#535455;
     --sub-sf-container22-txt-color:#8f8f8f;
     --sub-sf-container23-txt-color :#5095d7;
     --MuiRadio-colorSecondary-color:#10d877;
     --sf-color1-bg-color:#f53f68;
     --sf-color2-bg-color:#00c8f4;
     --sf-color3-bg-color:#fdc400;
     --sf-color4-bg-color:#984bd6;
     --sf-color5-bg-color:#10d877;
     --planning-change-template-color: #007BFF;
     --planning-change-template-default-color: #babdbf;
     --successfactorts-change-temp-border-color: #e9e8e8;
     --select-base-data-color: #f77766;
     --planning-tableheader-bg-color: #DCF1FB;
     --planning-table-cell-bg-color: #f3f3f3;
     --planning-table-cell-color: #5f5e5e;
     --planning-table-cell-second-color: #f1ecec;
     --planning-table-cell-second-border-color: #dbd7d7;
     --checkplus-lbl-color:#64696d;
     --planning-matiral-card-hover-color:#579bfc;
     --impress-reset-img-color: red;
     --impress-main-task-color: #f7f4f4;
     --planning-mile-color: #eae97e;
     --progress-notstarted-color: #c8c6c6;
     --progress-ontrack-color: #7db1f5;
     --progress-delayed-color: #f5c947;
     --progress-completed-color: #79da8d;
     --planning-textarea-border-color: #e3dede;
     --planning-textarea-color: #716b6b;
     --dashboard-teams-text-color: #FFF;
     --dashboard-teams-border-color: #e90a0a;
     --dashboard-teams-box-shawdow-active-color:#40373766;
     --dashboard-datatable-header-bg-color: #86caea;
     --dashboard-Y1SF1-bg-color: #F53F68;
     --dashboard-Y1SF2-bg-color:#00C8F4;
     --dashboard-Y1SF3-bg-color:#FDC400;
     --dashboard-Y1SF4-bg-color:#984BD6;
     --dashboard-Y1SF5-bg-color:#10D877;
     --dashborad-checkplus-lbl-color: #64696d;
     --dashboard-admin-border-color: #CDCDCD;
     --dashboard-admin-bs-color1:#00000033;
     --dashboard-admin-bs-color2: #00000024;
     --dashboard-admin-bs-color3: #0000001f;
     --dashboard-admin-selected-border-color: #6980E6;
     --dashboard-admin-selected-bs-color: #e97878;
     --dashboard-back-status-color: #5f5fe9;
     --dashboard-impress-table-header-color: #262729;
     --dashboard-edit-icon-color: #6ec6ff;
     --dashboard-tabs-box-shadow: #eceaf3;
     --cls-dashboard-text-color:#af5979;
     --milestone-icon-color: #ea3a77;
     

     --cls-searching-button-color :#9f999991;
     --cls-searching-button-border-color :#cdc5c5;
     --sf-search-color:rgb(185, 32, 32);
     --cls-team-cross-color:#7f8ecf;
     --cls-legend-box-bg-color:#fb5a5a;
     --cls-act-inp-description-bg-color:#e3dede;
     --cls-resetall-color:rgb(65, 65, 238);
     --cls-ref-lnk-color:#4672d5;
     --cls-tabs-active-color:#0287EA;
     --cls-tabs-active-border-color:#0287EA;
     --cls-search-icon-color:#eee;
     --MuiChip-root-bg-color:#f0f6ff;
     --MuiChip-root-border-color :#a7adb1;
     --popup-bg-color:rgba(0,0,0, 0.5);

     /* certificate colors  */
     --printer-color: #fff;
     --certificate-body-color: #fff;
     --printer-color-icon-color: #000000;
     --certificate-title-color: #333333;
     --certificate-name-color: #49518e;
     --accutrain-certificate-text-color: #354089;

     /* loginpage */
      --email-password-border-bottom: #c4c4c4;
      --email-password-text-color: #d1cece;
      --email-login-or-text-color: #969595;
      --email-login-or-border-color: #f1f0f0;
      --email-password-text-after-color: #919191;
      --forgot-password-back-icon: #505050;
      --forgot-password-back-text-color: #007bff;
      --username-password-placeholder-color: #ced4da;
      --forgot-password-authentication-bg-color: #dc3545;
      --change-password-here-label-color: rgba(0, 0, 0, 0.6);

     /* header  */
     --header-profile-drp-color: #6c757d;
     --header-hamburger-img-bg-color: #e7eef3;
     --header-profile-drp-active-color: #2d2d2d;
     --header-nav-link-border-color: #d2d7dc;
     --header-profile-ios-border-top-color: #e5e4e1;
     --header-search-icon-color: #6c757d;
     --header-navbar-color: #E6EBEF;
     /* --header-navbar-box-shawdow-color1:  */

     /* Sidebar */
     --sidebar-text-color: #fff;
     --sidebar-select-side-background-color: #019ade;
     --sidebar-background-color: #2d2d2d;
     --sidebar-background-shadow-color: 0 1px 8px 0 #0003, 0 3px 4px 0 #00000024, 0 3px 3px -2px #0000001f; 
     --sidehead-link-color:rgba(255, 255, 255, 0.6);
     --sideheadspan-color:#ffffff99;
     --align-i-color:rgba(255, 255, 255, 0.6);
     --sidehead-span-color:#484848;
     --sidebartopic-color:#5dd5e8;
     --sidebarpannel-color:#364760;
     --sidebarpannel-span-color:#f3f3f3;
     --sidegead-hover-color:#000000;
     --active-bg-color:rgba(0, 0, 0, 0.3);
     --actived-bg-color:#0000004d;
     --webkit-tap-highlight-color:rgba(0, 0, 0, 0);
     --form-control-webkit-color:rgba(100, 105, 109, 0.55);
     --active-menu-side-border-color: #7fbc03;

     
     /* setting */
     --settings-sub-head-color: #031b4e;
     --settings-col-left-text: #333;
     --settings-col-right-text: #031b4e;
     --setting-editlogo-text-color: #007bff;
     --form-group-required-after-color:red;
     --cls-check-group-top-color:#CDCDCD;
     --capsulesheader-bg-color:#818181;

     /* dashboard */
     --dashboard-header-text-color: #333;
     --dashboard-sub-header-text-color: #64696d;
     --dashboard-actuser-icon-color: #ff990080;
     --dashboard-actuser-bg-icon-color: #ff99001a;
     --dashboard-actuser-text-color: #000;
     --dashboard-totalrys-icon-color: #5c23cc80;
     --dashboard-totalrys-bg-icon-color: #5c23cc1a;
     --dashboard-totalrys-text-color: #000;

     /* userlist */
     --user-filter-header-text-color: #031b4e;
     --user-filter-icon-color: #031b4e;
     --user-filter-subheader-text-color: #716b6b;
     --user-filter-text-dropdown-color: #031b4e;
     --userlist-table-heading-color: rgba(0, 0, 0, 0.87);
     --userlist-table-datas-color: #716b6b;
     --userlist-table-active-color: #2da703;
     --userlist-table-inactive-color: #d90000;
     --userlist-table-edit-color: #007bff;
     --userlist-table-delete-color: #007bff;
     --userlist-table-reverify-color: #007bff;
     --userlist-selected-button-bg-color: rgb(33, 150, 243);
     --userlist-selected-button-border-color: rgb(33, 150, 243);
     --userlist-selected-button-text-color: gb(255, 255, 255);
     --userlist-count-button-color: #2196f3;
     --userlist-count-button-text-color: :#fff;

     /* grouplist */
     --grouplist-table-heading-color: rgba(0, 0, 0, 0.87);
     --grouplist-table-datas-color: #716b6b;
     --grouplist-table-active-color: #2da703;
     --grouplist-table-inactive-color: #d90000;
     --grouplist-table-edit-color: #007bff;
     --grouplist-table-delete-color: #007bff;
     --grouplist-table-reverify-color: #007bff;
     --grouplist-selected-text-color: #000;
     --grouplist-sub-heading-text-color: #333;

     /* Assignment */
     --Assignment-sub-heading-color: #1e1e1e;
     --Assignment-table-heading-color: rgba(0, 0, 0, 0.87);
     --Assignment-table-datas-color: #716b6b;
     --Assignment-table-edit-color: #007bff;

     /* Recordcompletion */
     --rc-sub-heading-text-color: #333;
     --rc-filter-header-text-color: #031b4e;
     --rc-filter-icon-color: #031b4e;
     --rc-filter-subheader-text-color: #716b6b;
     --rc-filter-text-dropdown-color: #031b4e;
     --rc-table-heading-color: rgba(0, 0, 0, 0.87);
     --rc-table-datas-color: #716b6b;

     /* Reset Training */
     --rt-sub-heading-text-color: #333;
     --rt-filter-icon-color: #031b4e;
     --rt-table-heading-color: rgba(0, 0, 0, 0.87);
     --rt-table-datas-color: #716b6b;
     --player-controls-shadow-color:rgba(0,0,0,0.4);

     /* Toolbox */
     --toolbox-sub-head-color: #496077;
     --toolbox-arrow-color: #031b4e;
     --modal-content-color:rgba(0, 0, 0, 0.5);
     --popup-header-fname-color:#ffffff;
     --cucu-btn-color:rgba(0, 0, 0, 0.4);
     --filename-color:#04358d;
     --fdr-str-hover-color:#47a1ef;
     --fdr-view-color:#fdfdfd;
     --lsthdr-color:#c2c1c1;
     --fileview-color:#edebeb;
     --fileview-hover-color:#f8f7f7;
     --fileView-color:#c9c2c2;

      /* Rate your state */
      --row-ctn-color:rgb(251, 251, 251);
      --rate-sub-box-bg-color: #c4cbd4;
      --rate-sub-card-i-color:#018001;
      --rateinfo-color:#e91e63;
      --rate-disable-color:rgba(113, 113, 113, 0.4);
      --rys-text-color:#000;
      --checkmark-img-color:rgba(0, 0, 0, 0.1);
     --ssi-rys-qa-cardbd-bg-opts:#0099ff;
     --rate-ifield-text-brdclr-color:#00ffff;
     --input-range-slider:#3f51b5;
     --input-range-slider-color:#888888;
     --input-range-label-color:#031b4e;
     --ebkit-scrollbar-track-bg-color:#f1f1f1;
     --webkit-scrollbar-thumb:#b1b1b1;
     --webkit-scrollbar-thumb-hover-color:#555;
     --qa-rate-board-usr-radio:#f7f7f7;
     --rate-dp-bg-color:#e4e4e4 ;
     --react-date-picker-bg-color:#e0e0e0;
     --white-bg-color:white;
     --label-check-color:rgba(0, 0, 0, 0.5);
     --label-check-hovor-color:rgba(0, 0, 0, 0.2);
     --label-text-label-check:#00d478;

     /* Survey Updated  */
     --checked-label-color:#3f4b28;
     --range-value-color:#7FBC03;
     --textarea-bg-color: #dfeec0;
     --progress-bar-ctn-color:#dfeec0;
     --previous-btn-bg-color: rgba(0, 0, 0, 0.40);


     /* Steering commitee */
     --sc-sub-heading-color: #757575;

     /* Storyfeeds */
     --imageCloseBtn-bg-color:#6d6969;
     --sf-createpost-bg-color: #dfdfdf;
     --sf-createpost-text-color: #4277ec;
     --sf-username-text-color: #505050;
     --sf-hours-color: #8e8e8e;
     --sf-profile-text-color: #fff;
     --sf-profile-bg-color: #4f8dc5;
     --sf-subheading-color: #031b4e;
     --sf-icons-color: #b2b2b3;
     --sf-post-text-color: #019ade;
     --sf-channels-text-color: #019ade;
     --sf-sub-head-color: #212529;
     --sf-channels-sub-head-color: #878585;
     --sf-your-channel-text-color: #007bff;
     --sf-feedsPostContainer-color:#cccccc;
     --sf-feedstHeader-color:#737373;
     --channelListInPost-color:#ababab;
     --postChannelListDropdown-placeholder:#818080;
     --sf-readContaineNoFeedData-color:#9f0303;
     --sf-feedsPostEmojiHolder-border:#f2f87e;
     --sf-feedsPostEmojiHolder-bg-color:#0707e9;
     --sf-feedsStory-color:#797979;
     --sf-feedsChannelNavStrip-hover:#f8f9f9;
     --sf-feedsChannelItemBtn-color:#969494;
     --sf-feedsChannelItemBtn-hover-color:#3f456f;
     --sf-alertContainerWrapper-color:rgba(149, 146, 146, 0.5);
     --sf-channelName-color :#5f5f5f;
     --sf-morePopupMenuItem-hover:#f3f6f8;
     --sf-formContainer-bg-color:rgba(100, 100, 100, 0.6);
     --sf-fomButtonContainer-bg-color:#767676;
     --sf-legendsIcon-color:#ddd;
     --sf-userListLegends-color:#c7c9ca;
     --userListAssigned-hover:#007fb7;
     --sf-channelHeaderName-color:#424040;
     --sf-profileRole-color:#a0a0a0;
     --sf-descriptionBlockContent-color:#9a9a9a;
     --sf-chnnelDropdown-border-color:#0088c5;
     --sf-displayBlock-color:#e5e4e1;
     --sf-removeicon-color:#8c8c8c;
     --sf-TextHolder-color:#efefef;
     --sf-ContentOverFlowWrapper-color:#493fe3;
     --sf-displayBlockLikeBtn-color:#8080b0;
     --sf-feedLiked-color:#1485bd;
     --sf-displayBlockComments-color:hsla(0, 0%, 96.9%, 0.5);
     --sf-displayBlockLike-color:#959697;
     --sf-feedsLoadingContainer-color:rgba(189, 189, 189, 0.5);
     --fa-list-color:rgba(1, 154, 222, 0.2);
     --fa-col-color:#9E9E9E;
     --fa-pc-h5-color:rgba(0,0,0,.1);
     --fa-trash-alt-color:#fd7e14;
     --fa-check-square-bg-color:#15aabf;
     --sf-imageCloseBtn-color:#363636;
     --sf-imageCloseBtn-bg-color:rgba(255, 255, 255, 0.8);
     --sf-input-box-color:lightgray;
     --sf-modal-two-color:#34373b;
     --sf-input-box-text-color:#b8b8b8;
     --sf-cool-link-color:skyblue;
     --feedsPostHeader-color:#f7f5f5;
     --displayBlockRemoveBtnPannel-color:#707070;
     --sf-assertsViewerHeaderContaner-color:#1e1c1c;
     --assertsViewerContentContaner-color:#121212;
     --assetsViewer-btn-hover:#939adf;
     --postFileHolder-color:#e5e5e7;
     --FileTag-color:#59595a;
     --FileCloseBtn-color:#37379c;
     --process-cover-color:#007bff2b;
     --stripContainer-color:#585858;
     --deleteContainer-color:#a9a9a9;

    /* Closecall */
     --cc-question-text-color: #2751e9;
     --cc-sub-head-color: rgba(0, 0, 0, 0.54);
     --cc-incident-sub-head-color: #4b72b5;
     --cc-source-button-bg-color: #c1ebbc;
     --cc-source-button-text-color: #000;
     --cc-severity-button-text-color: ?;
     --cc-severity-button-border-color: solid rgb(166, 165, 165);
     --cc-severity-serious-button-bg-color: rgb(192, 0, 0);
     --cc-severity-major-button-bg-color: rgb(252, 2, 1);
     --cc-severity-minor-button-bg-color: rgb(249, 125, 42);
     --cc-severity-low-button-bg-color: rgb(251, 232, 5);
     --cc-severity-verylow-button-bg-color: rgb(146, 208, 81);
     --cc-chooseimg-text-color: #007bff;
     --cc-filter-icon-color: #212529;

     /* Reports */
     --rpt-sub-head-text-color: #031b4e;
     --rpt-filterbar-bg-color: white;
     --rpt-filterbar-border-color: rgba(0, 0, 0, 0.12);
     --rpt-filter-icon-color: #212529;
     --rpt-popup-columns-text-color: #64696d;
     --rpt-popup-subhead-text-color: #031b4e;
     --rpt-popup-filter-searchbar-text-color: rgb(100, 105, 109);
     --rpt-popup-filter-searchbar-border: rgb(206, 212, 218);
     --rpt-popup-more-text-color: blue;
     --rpt-popup-filter-selectedbar-color: solid grey;
     --rpt-export-prop-color: #212529;
     --rpt-table-upper-heading-text-color: #031b4e;
     --rpt-table-heading-text-color: rgba(0, 0, 0, 0.87);
     --rpt-table-datas-text-color: #716b6b;
     --rpt-table-active-color: #2da703;
     --rpt-table-inactive-color: #d90000;
     --rpt-tbl-upperhead-lefttext-color: #031b4e;
     --rpt-tbl-upperhead-righttext-color: blue;
     --searchlistbox-cust-report-border-color: #0000001f;

     /* Learner progress*/
     --lp-chart-number-color: #2d2d2d;
     --lp-chart-text-color: #031b4e;

     /* Learner Course Progress */
     --lcp-course-col-bg-color: #b8daff;
     --lcp-unit-col-bg-color: #0000000d;

     /* Historical Learner Courses */
     --hlc-popup-datebar-color: #e9ecef;
     --hlc-popup-datebar-text-color: #64696d;

     /* Refresher Capsule Summary */
     --rfs-course-col-bg-color: #b8daff;
     --rfs-col-header-text-color: #5d5d5d;
     --rfs-unit-col-bg-color: #0000000d;

     /* Risk Pattern */
     --rp-sub-head-color: #000;
     --rp-question-text-color: #1a3598;
     --rp-qus-sub-text-color: inherit; 

     /* Survey Management */
     --qr-steps-bg-color: #F2F1F1;
     --guide-qr-label-color: #3399FF;
     --survey-setting-tab-hr-color: #3399FF;
     --survey-settings-input-border: #3399FF;
     --poster-survey-qr-code: #007EE0;
     --link-tooltip-color: #333;
}
