.input-field{
    color: var(--input-text-color);
    font-family: var(--ff-rhas);
    font-size: var(--font-13px);
    font-weight: var(--font-Medium);
    min-width: 60px;
    padding: 9px 15px;
    border-radius: 5px;
    background-color: var(--primary-btn-text-color);
    border: 1px solid var(--searchbar-border-color); 
}

.input-field::placeholder {
    color: var(--input-placeholdertext-color);
}

.input-field:focus,.input-field:focus-visible,.input-field:focus-within {
    outline:none;
    border: 1px solid var(--searchbar-border-highlight-color);
}

.input-field:disabled,
.input-field[readonly] {
  cursor: not-allowed;
  background-color: var(--input-placeholder-disabled);
}

@media (max-width: 767px){
    .input-field{
        max-height: 40px;
    }
}
/*** redux- form field label style ***/
/* .form-group label {
    color: var(--label-text);
    font-family: var(--ff-rhas);
    font-size: var(--font-13px);
    font-weight: var(--font-Semi-Bold);
    letter-spacing: .1px;
    margin-bottom: 5px;
} */